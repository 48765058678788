/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.delicious.com/save?v=5&noui&jump=close',
                params: {
                    url: 'url',
                    title: 'title'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Delicious',
        be: 'Delicious',
        en: 'Delicious',
        hy: 'Delicious',
        ka: 'Delicious',
        kk: 'Delicious',
        ro: 'Delicious',
        ru: 'Delicious',
        tr: 'Delicious',
        tt: 'Delicious',
        uk: 'Delicious',
        uz: 'Delicious'
    },

    color: '#31a9ff'
};

/** @type {TemplatesConstructor} */
export function getPopupTemplate(constructors, state) {
    const { elem, mElem } = constructors;
    const { hasCurtain } = state;

    const templates = {};

    if (hasCurtain) {
        templates.popup = ({ services, url = '', customContent, copy, needExtendMessenger }) => ({
            mods: {
                mobile: true
            },
            content: [
                mElem('popup-overlay'),
                mElem('popup-content', { services, url, customContent, copy, needExtendMessenger })
            ]
        });
    } else {
        templates.popup = ({ services, url = '', copy = 'last', popupDirection, listDirection, needExtendMessenger }) => {
            const list = elem('list', {
                direction: 'vertical',
                services
            });

            if (copy === 'first' && needExtendMessenger) {
                list.content[0].splice(1, 0, elem('item_copy', url));
            } else if (copy === 'first') {
                list.content[0].unshift(elem('item_copy', url));
            } else if (copy === 'last') {
                list.content[0].push(elem('item_copy', url));
            } // Do nothing if copy === 'hidden'

            popupDirection = popupDirection === 'top' ?
                'top' :
                popupDirection === 'auto' ?
                    'auto' :
                    'bottom';
            listDirection = listDirection === 'vertical' ? 'vertical' : 'horizontal';

            return {
                mods: {
                    direction: popupDirection,
                    'list-direction': listDirection
                },
                content: [
                    needExtendMessenger && elem('messenger-contacts'),
                    list
                ]
            };
        };
    }

    return templates;
}

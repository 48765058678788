const MESSENGER_NAME = 'messenger';

/**
 * Полифилл для findIndex
 * @param {Array} array
 * @param {Function} cb Функция сравнения
 */
const findIndex = (array, cb) => array
    .map((item, i) => ({ item, i }))
    .filter(({ item }) => cb(item))
    .map(({ i }) => i)[0];

/**
 * Готовит сервисы к отправке в шаблонизатор
 * @param {string[]} services
 * @param {Object} options
 * @param {number} options.limit
 * @param {boolean} options.needExtendMessenger
 * @returns {Object}
 */
export function prepareServicesList(services, options) {
    const { limit, needExtendMessenger } = options;

    if (needExtendMessenger && limit !== undefined) {
        services = services.slice(0);
        const yandexMessengerIndex = findIndex(services, item => item.toLowerCase() === MESSENGER_NAME);

        if (yandexMessengerIndex !== undefined) {
            services.splice(yandexMessengerIndex, 1);
        }

        services.splice(limit, 0, MESSENGER_NAME);
    }

    return services;
}

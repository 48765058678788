/**
 * Объект значений параметров по переданной карте
 * @param {Object} pluginParams - карта параметров для построения URL
 * @param {Object} data
 * @return {Object}
 */
function getParams(pluginParams, data) {
    return Object.keys(pluginParams).reduce((res, param) => {
        const dataKey = pluginParams[param];

        if (typeof dataKey === 'object') {
            const newValue = dataKey.options.reduce((res, dataKey) => {
                const value = data[dataKey];

                if (value) {
                    res.push(value);
                }

                return res;
            }, []).join(dataKey.separator);

            res[param] = newValue;

            return res;
        }

        const value = data[dataKey];

        if (value) {
            res[param] = value;
        }

        return res;
    }, {});
}

/**
 * URL плагина
 * @param {Object} config
 * @param {Object} data
 * @return {String}
 */
export function buildUrl(config, data) {
    const params = getParams(config.params, data);

    const query = serializeParams(params) + '&utm_source=share2';
    const separator = config.baseUrl.indexOf('?') === -1 ? '?' : '&';

    return `${config.baseUrl}${separator}${query}${config.restUrl || ''}`;
}

/**
 * Serialize given params into GET string
 *
 * @param  {Object} params
 * @return {String}
 */
export function serializeParams(params) {
    return Object
        .keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');
}

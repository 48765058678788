/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey',
                params: {
                    url: 'url',
                    title: 'title',
                    pics: 'image'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Qzone',
        be: 'Qzone',
        en: 'Qzone',
        hy: 'Qzone',
        ka: 'Qzone',
        kk: 'Qzone',
        ro: 'Qzone',
        ru: 'Qzone',
        tr: 'Qzone',
        tt: 'Qzone',
        uk: 'Qzone',
        uz: 'Qzone'
    },

    color: '#f5b53c'
};

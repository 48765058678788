/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.linkedin.com/shareArticle?mini=true',
                params: {
                    url: 'url',
                    title: 'title',
                    summary: 'description'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'LinkedIn',
        be: 'LinkedIn',
        en: 'LinkedIn',
        hy: 'LinkedIn',
        ka: 'LinkedIn',
        kk: 'LinkedIn',
        ro: 'LinkedIn',
        ru: 'LinkedIn',
        tr: 'LinkedIn',
        tt: 'LinkedIn',
        uk: 'LinkedIn',
        uz: 'LinkedIn'
    },

    color: '#0083be'
};

import bemNaming from 'bem-naming';

import dom from './dom';

const bem = {
    findInside: function (context, bemjson) {
        return context.querySelectorAll(`.${bemNaming.stringify(bemjson)}`);
    },

    findOutside: function (context, bemjson) {
        return dom.closest(context, bemNaming.stringify(bemjson));
    },

    getMod: function (context, modName) {
        for (let i = 0, len = context.classList.length; i < len; i += 1) {
            const bemjson = bemNaming.parse(context.classList[i]);

            if (bemjson && bemjson.modName === modName) {
                return bemjson.modVal;
            }
        }
    }
};

export default bem;

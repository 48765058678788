/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://yandex.ru/chat',
                restUrl: '#/forward',
                params: {
                    text: {
                        options: ['title', 'url'],
                        separator: '\n'
                    }
                }
            }
        }
    },

    i18n: {
        az: 'Я.Мессенджер',
        be: 'Я.Мессенджер',
        en: 'Yandex.Messenger',
        hy: 'Yandex.Messenger',
        ka: 'Yandex.Messenger',
        kk: 'Я.Мессенджер',
        ro: 'Yandex.Messenger',
        ru: 'Я.Мессенджер',
        tr: 'Yandex.Messenger',
        tt: 'Я.Мессенджер',
        uk: 'Yandex.Messenger',
        uz: 'Yandex.Messenger'
    },

    color: '#03CECE'
};

export function open(namespace, href, dimensions) {
    const id = `${namespace}${Date.now()}`;
    const [width, height] = dimensions;
    const params = {
        scrollbars: 1,
        resizable: 1,
        menubar: 0,
        toolbar: 0,
        status: 0,
        left: (screen.width - width) / 2,
        top: (screen.height - height) / 2,
        width,
        height
    };
    const paramsString = Object.keys(params).map(key => `${key}=${params[key]}`).join(',');
    const popup = window.open(href, id, paramsString);

    if (popup) {
        popup.focus();
    }
}

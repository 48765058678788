/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.blogger.com/blog-this.g',
                params: {
                    t: 'description',
                    u: 'url',
                    n: 'title'
                }
            }
        }
    },

    popupDimensions: [800, 320],

    i18n: {
        az: 'Blogger',
        be: 'Blogger',
        en: 'Blogger',
        hy: 'Blogger',
        ka: 'Blogger',
        kk: 'Blogger',
        ro: 'Blogger',
        ru: 'Blogger',
        tr: 'Blogger',
        tt: 'Blogger',
        uk: 'Blogger',
        uz: 'Blogger'
    },

    color: '#fb8f3d'
};

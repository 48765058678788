/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'http://widget.renren.com/dialog/share',
                params: {
                    resourceUrl: 'url',
                    srcUrl: 'url',
                    title: 'title',
                    pic: 'image',
                    description: 'description'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Renren',
        be: 'Renren',
        en: 'Renren',
        hy: 'Renren',
        ka: 'Renren',
        kk: 'Renren',
        ro: 'Renren',
        ru: 'Renren',
        tr: 'Renren',
        tt: 'Renren',
        uk: 'Renren',
        uz: 'Renren'
    },

    color: '#1760a7'
};

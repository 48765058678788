/**
 * Формирует атрибут rel для ссылки
 * @param {string} location
 */
export const getRel = location => {
    let rel = ['nofollow'];

    if (/^https?:/.test(location)) {
        rel.push('noopener');
    }

    return rel.join(' ');
};

/**
 * Получает ссылку на мета-тэг
 * @param {string} name
 * @param {string} value
 */
export const getMetaTag = (name, value) => document.querySelector(`meta[${name}="${value}"]`);

/**
 * Получает ссылку на мета-тэг og-разметки
 * @param {string} name
 */
export const getOgTag = name => getMetaTag('property', `og:${name}`);

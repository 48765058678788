
import extend from 'extend';

import Share from './share';
import dom from './dom';
import { getCss } from './builder';
import Document from './document';
import bem from './bem';

const doc = new Document(window.document);
let stylesLoaded = false;

export default function facade(params) {
    return function (containers, options = {}) {
        if (typeof containers === 'string') {
            containers = dom.toArray(document.querySelectorAll(containers));
        }

        if (!Array.isArray(containers)) {
            containers = [containers];
        }

        if (options.reinit === false) {
            containers = containers.filter(function (container) {
                return !bem.getMod(container, 'inited');
            });
        }

        return containers.map(container => {
            const share = new Share(container, extend({ options }, params));

            if (!share.isBare() && !stylesLoaded) {
                doc.injectCss(getCss(params.plugins), { nonce: share.getNonce() });
                stylesLoaded = true;
            }

            return share;
        });
    };
}

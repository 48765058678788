const dom = {
    closest: function (node, className) {
        do {
            if (node.classList && node.classList.contains(className)) {
                return node;
            }
        } while (node = node.parentNode); // eslint-disable-line no-cond-assign
    },

    toArray: function (arrayLike) {
        const result = [];
        const len = arrayLike.length;

        for (let i = 0; i < len; i += 1) {
            result.push(arrayLike[i]);
        }

        return result;
    },

    getTarget: function (event) {
        return event.target || event.srcElement;
    },

    remove: function (node) {
        return node.parentNode.removeChild(node);
    },

    getRectRelativeToDocument: function (node) {
        let rect = node.getBoundingClientRect();

        // Chrome >40 always return 0 for document.documentElement.scrollTop
        let topScroll = window.scrollY === undefined ? document.documentElement.scrollTop : window.scrollY;
        let leftScroll = window.scrollX === undefined ? document.documentElement.scrollLeft : window.scrollX;

        let top = rect.top + topScroll;
        let left = rect.left + leftScroll;

        let width = rect.width === undefined ? rect.right - rect.left : rect.width;
        let height = rect.height === undefined ? rect.bottom - rect.top : rect.height;

        return { top, left, width, height };
    }
};

export default dom;

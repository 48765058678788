import extend from 'extend';
import BH from 'bh-custom';

import { mobileTemplates } from './common-templates/templates-mobile';
import { getPopupTemplate } from './conditional-templates/template-popup';
import { commonTemplates } from './common-templates/templates-common';
import { mobileContactsTemplates } from './common-templates/templates-mobile-contacts';
import { getMobileBody } from './conditional-templates/template-mobile-body';
import { getMessengerContactsListTemplate } from './conditional-templates/template-messenger-contacts-list';

export const ConstructorName = Object.freeze({
    elem: 'elem',
    messengerContactsList: 'messengerContactsList',
    popupTemplate: 'popupTemplate',
    mElem: 'mElem',
    mBody: 'mBody',
    mContactsElem: 'mContactsElem'
});

/**
 * @param {Object} i18n
 * @param {boolean} hasCurtain
 * @returns {InitTemplatesReturns}
 */
export function initTemplates(i18n, hasCurtain) {
    /** @type {State} */
    const state = { hasCurtain, i18n };

    /** @type {Constructors} */
    const constructors = {
        [ConstructorName.elem]: createConstructor(ConstructorName.elem),
        [ConstructorName.messengerContactsList]: createConstructor(ConstructorName.messengerContactsList),
        [ConstructorName.popupTemplate]: createConstructor(ConstructorName.popupTemplate),
        [ConstructorName.mElem]: createConstructor(ConstructorName.mElem),
        [ConstructorName.mBody]: createConstructor(ConstructorName.mBody),
        [ConstructorName.mContactsElem]: createConstructor(ConstructorName.mContactsElem)
    };

    /** @type {Templates} */
    const templates = {
        [ConstructorName.elem]: commonTemplates(constructors, state),
        [ConstructorName.messengerContactsList]: getMessengerContactsListTemplate(constructors, state),
        [ConstructorName.popupTemplate]: getPopupTemplate(constructors, state),
        [ConstructorName.mElem]: mobileTemplates(constructors, state),
        [ConstructorName.mBody]: getMobileBody(constructors, state),
        [ConstructorName.mContactsElem]: mobileContactsTemplates(constructors, state)
    };

    /**
     * Создает функцию-генератор для нужного шаблона
     * @example
     * const elem = createConstructor('templates');
     * elem('icon', { link: '' });
     * @param {'common' | 'mobile' | 'conditional'} template - Название шаблона
     * @returns {GenerateElem}
     */
    function createConstructor(template) {
        return (name, params) => extend(
            {
                block: 'ya-share2',
                elem: name
            },
            templates[template][name](params)
        );
    }

    const bh = new BH();

    return {
        update: (context, name, params = {}, constructorName = 'elem') => {
            const elem = constructors[constructorName];
            context.innerHTML = bh.toHtml(elem(name, params));
        },

        replaceNode: (replacedNode, name, params = {}, constructorName = 'elem') => {
            const elem = constructors[constructorName];
            replacedNode.outerHTML = bh.toHtml(elem(name, params));
        }
    };
}

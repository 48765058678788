/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.reddit.com/submit',
                params: {
                    url: 'url',
                    title: 'title'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'reddit',
        be: 'reddit',
        en: 'reddit',
        hy: 'reddit',
        ka: 'reddit',
        kk: 'reddit',
        ro: 'reddit',
        ru: 'reddit',
        tr: 'reddit',
        tt: 'reddit',
        uk: 'reddit',
        uz: 'reddit'
    },

    color: '#ff4500'
};

export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://pinterest.com/pin/create/button/',
                params: {
                    url: 'url',
                    media: 'image',
                    description: 'title'
                }
            }
        }
    },

    linkAttrs: {
        'data-pin-do': 'none'
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Pinterest',
        be: 'Pinterest',
        en: 'Pinterest',
        hy: 'Pinterest',
        ka: 'Pinterest',
        kk: 'Pinterest',
        ro: 'Pinterest',
        ru: 'Pinterest',
        tr: 'Pinterest',
        tt: 'Pinterest',
        uk: 'Pinterest',
        uz: 'Pinterest'
    },

    color: '#c20724'
};

/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.tumblr.com/share/link',
                params: {
                    url: 'url',
                    description: 'description'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Tumblr',
        be: 'Tumblr',
        en: 'Tumblr',
        hy: 'Tumblr',
        ka: 'Tumblr',
        kk: 'Tumblr',
        ro: 'Tumblr',
        ru: 'Tumblr',
        tr: 'Tumblr',
        tt: 'Tumblr',
        uk: 'Tumblr',
        uz: 'Tumblr'
    },

    color: '#547093'
};

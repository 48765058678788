/** @type {TemplatesConstructor} */
export function mobileContactsTemplates(constructors) {
    const { mElem } = constructors;

    return {
        'popup-tile-wrapper': services => ({
            content: mElem('popup-tile', services)
        })
    };
}

/**
 * This class is responsible for interacting with window.document
 *
 * @param  {Object} _document
 */
const Document = function (_document) {
    this._document = _document;
};

function getContainer(_document) {
    return _document.getElementsByTagName('head')[0] || _document.body;
}

/**
 * Insert style element with given context into the page
 *
 * @param  {String} css
 */
Document.prototype.injectCss = function (css, { nonce }) {
    const container = getContainer(this._document);
    const element = this._document.createElement('style');

    element.type = 'text/css';
    element.innerHTML = css;

    if (nonce) {
        element.setAttribute('nonce', nonce);
    }

    container.appendChild(element);
};

export default Document;

/**
 * Insert script element with given source into the page
 *
 * @param  {String} src
 * @return {DOMNode}
 */
export function injectJs(src) {
    const element = document.createElement('script');

    element.src = src;
    element.defer = true;

    document.head.appendChild(element);

    return element;
}

/**
 * Runs given function when DOM is parsed
 *
 * @link https://github.com/jquery/jquery/blob/master/src/core/ready.js#L60-L86
 */
export function ready(fn) {
    // The ready event handler and self cleanup method
    function completed() {
        document.removeEventListener('DOMContentLoaded', completed);
        window.removeEventListener('load', completed);
        fn();
    }

    // Catch cases where $(document).ready() is called
    // after the browser event has already occurred.
    // Support: IE <=9 - 10 only
    // Older IE sometimes signals 'interactive' too soon
    if (document.readyState === 'complete' ||
        (document.readyState !== 'loading' && !document.documentElement.doScroll)) {
        fn();
    } else {
        // Use the handy event callback
        document.addEventListener('DOMContentLoaded', completed);

        // A fallback to window.onload, that will always work
        window.addEventListener('load', completed);
    }
}

import { injectJs } from './document';

// Для нашего счетчика 26812653 в метрике есть условие, которое запрещает логирование с помощью _ym_debug=1
// Возможно проверить корректность с помощью "плагина от испанца" https://st.yandex-team.ru/METRIKASUPP-11524#606c867289492e172e848a5d

/**
 * Ищет на странице подключенный скрипт метрики
 */
function hasMetrikaScriptInDOM(scriptName, scriptTags) {
    const metrikaUrlRegExp = new RegExp(`^https://mc\\.yandex\\.ru/metrika/${scriptName}\\.js$`);
    const cdnUrlRegExp = new RegExp(`^https://cdn\\.jsdelivr\\.net/npm/yandex-metrica-watch/${scriptName}\\.js$`);

    return scriptTags.some(({ src }) =>
        metrikaUrlRegExp.test(src) ||
        cdnUrlRegExp.test(src)
    );
}

/**
 * Проверяет, инициализирована первая или вторая метрика на странице
 */
function hasMetrikaInWindow() {
    return window.Ya && ('Metrika' in window.Ya || 'Metrika2' in window.Ya);
}

/**
 * Проверяет, есть ли в window метричная функция ym()
 */
function hasYmInWindow() {
    return Boolean(window.ym);
}

/**
 * Проверяет, валидная ли метричная функция ym()
 */
function isValidYm() {
    return Array.isArray(window.ym.a);
}

/**
 * Проверяет, есть ли первая метрика в window
 */
function hasFirstMetrikaInWindow() {
    return window.Ya.Metrika && !window.Ya.Metrika2;
}

class Metrika {
    constructor(id) {
        this._id = id;
        this.strategy = {
            value: ''
        };

        this.traceMethod = this.traceMethod.bind(this);
    }

    traceMethod(name) {
        this.strategy.value += this.strategy.value ? `/${name}` : name;
    }

    /**
     * Вызовет функцию, когда будет загружена вторая метрика
     * @param {function} callback
     */
    pushCallback(callback) {
        const namespace = 'yandex_metrika_callbacks2'; // Если инициализируем через pushCallback, то всегда грузится вторая версия

        window[namespace] = window[namespace] || [];
        window[namespace].push(callback);
    }

    /**
     * Стабает функцию ym так, чтобы сигнатура совпадала со стандартной функцией использования метрики
     * @param {string} metrikaConstructorName
     */
    stubYm(metrikaConstructorName) {
        this.traceMethod(metrikaConstructorName === 'Metrika' ? 'h' : 'i');
        let metrika;

        this.ym = (method, ...options) => {
            if (method === 'init') {
                const MetrikaConstructor = window.Ya[metrikaConstructorName];
                metrika = new MetrikaConstructor({ id: this._id, ...options[0] });
            } else {
                metrika[method](...options);
            }
        };
    }

    /**
     * Имплементирует стандартную функцию использования метрики
     */
    injectYm() {
        window.ym = (...args) => window.ym.a.push(args);

        window.ym.a = [];

        this.ym = window.ym.bind(null, this._id);
    }

    /**
     * Имплементирует this.ym для использования второй метрики
     */
    setYmMethodForMetrika2() {
        this.traceMethod('n');
        if (hasYmInWindow()) {
            if (isValidYm()) {
                this.traceMethod('o');
                // Функция ym() есть и валидная
                this.ym = window.ym.bind(null, this._id);
            } else {
                this.traceMethod('p');
                // Функция ym() есть, но не валидная (например, от вебмастера)
                this.stubYm('Metrika2');
            }
        } else {
            this.traceMethod('q');
            // Нет функции ym()
            this.injectYm();
        }
    }

    /**
     * Инжектим руками скрипт загрузки второй метрики на странице
     */
    injectMetrikaScript() {
        this.setYmMethodForMetrika2();

        this.traceMethod('r');
        const script = injectJs('https://mc.yandex.ru/metrika/tag.js');

        this.pushCallback(() => {
            this.traceMethod('s');
            this.initYm();

            if (script) {
                script.parentNode.removeChild(script);
            }
        });
    }

    /**
     * Ждем, пока подгрузится имеющийся в DOM скрипт метрики
     */
    waitMetrikaLoading() {
        this.traceMethod('j');

        let intervalTimer = null;
        let timeoutTimer = null;

        const checkMetrikaInit = () => {
            this.traceMethod('k');
            // Если метрика инициализовалась, чистим все таймеры и создаем счетчик
            if (hasMetrikaInWindow()) {
                this.traceMethod('l');
                clearInterval(intervalTimer);
                clearTimeout(timeoutTimer);

                this.initYm();

                return true;
            }
        };

        intervalTimer = setInterval(checkMetrikaInit, 45);

        const timeoutStrategy = () => {
            if (checkMetrikaInit()) {
                return;
            }

            this.traceMethod('m');
            // Если метрика не инициализовалась спустя 400мс, инжектим скрипт загрузки
            clearInterval(intervalTimer);

            this.injectMetrikaScript();
        };

        timeoutTimer = setTimeout(timeoutStrategy, 400);
    }

    tagJsLoadingStrategy() {
        this.setYmMethodForMetrika2();
        this.waitMetrikaLoading();
    }

    watchJsLoadingStrategy() {
        this.stubYm('Metrika');
        this.waitMetrikaLoading();
    }

    hasMetrikaStrategy() {
        switch (true) {
            // Первая метрика
            case (hasFirstMetrikaInWindow()): {
                this.traceMethod('e');
                this.stubYm('Metrika');
                break;
            }

            // Вторая метрика и есть валидная функция ym()
            case (hasYmInWindow() && isValidYm()): {
                this.traceMethod('f');
                this.ym = window.ym.bind(null, this._id);
                break;
            }

            // Вторая метрика и нет валидной ym()
            default: {
                this.traceMethod('g');
                this.stubYm('Metrika2');
                break;
            }
        }

        this.initYm();
    }

    init() {
        if (hasMetrikaInWindow()) {
            this.traceMethod('a');
            return this.hasMetrikaStrategy();
        }

        const scriptTags = Array.prototype.slice.call(document.getElementsByTagName('script'));
        if (hasMetrikaScriptInDOM('watch', scriptTags)) {
            this.traceMethod('b');
            return this.watchJsLoadingStrategy();
        }

        if (hasMetrikaScriptInDOM('tag', scriptTags)) {
            this.traceMethod('c');
            return this.tagJsLoadingStrategy();
        }

        this.traceMethod('d');
        this.injectMetrikaScript();
    }

    /**
     * Инициализация счетчика
     */
    initYm() {
        this.traceMethod('t');

        this.ym('init', {
            trackLinks: true,
            accurateTrackBounce: true,
            params: {
                shareVersion: 2,
                strategy: this.strategy.value
            },
            triggerEvent: true
        });
    }
}

export default Metrika;

export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://connect.mail.ru/share',
                params: {
                    url: 'url',
                    title: 'title',
                    description: 'description'
                }
            }
        }
    },

    popupDimensions: [560, 400],

    i18n: {
        az: 'Moy Mir',
        be: 'Мой Мир',
        en: 'Moi Mir',
        hy: 'Moi Mir',
        ka: 'Moi Mir',
        kk: 'Мой Мир',
        ro: 'Moi Mir',
        ru: 'Мой Мир',
        tr: 'Moi Mir',
        tt: 'Мой Мир',
        uk: 'Мой Мир',
        uz: 'Moy Mir'
    },

    color: '#168de2'
};

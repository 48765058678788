/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://digg.com/submit',
                params: {
                    url: 'url',
                    title: 'title',
                    bodytext: 'description'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Digg',
        be: 'Digg',
        en: 'Digg',
        hy: 'Digg',
        ka: 'Digg',
        kk: 'Digg',
        ro: 'Digg',
        ru: 'Digg',
        tr: 'Digg',
        tt: 'Digg',
        uk: 'Digg',
        uz: 'Digg'
    },

    color: '#000'
};

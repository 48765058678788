/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.evernote.com/clip.action',
                params: {
                    title: 'title',
                    body: 'description',
                    url: 'url'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Evernote',
        be: 'Evernote',
        en: 'Evernote',
        hy: 'Evernote',
        ka: 'Evernote',
        kk: 'Evernote',
        ro: 'Evernote',
        ru: 'Evernote',
        tr: 'Evernote',
        tt: 'Evernote',
        uk: 'Evernote',
        uz: 'Evernote'
    },

    color: '#24d666'
};

/** @type {TemplatesConstructor} */
export function getMobileBody(constructors, state) {
    const { mElem, elem, mContactsElem } = constructors;
    const { i18n } = state;

    const templates = {};

    const defaultPopup = ({ services, url, copy }) => {
        const content = [mElem('popup-tile', services)];

        if (copy) {
            content.push(mElem('mobile-popup-copy-link', url));
        }

        return {
            content
        };
    };

    const popupWithContacts = ({ services, url, copy }) => {
        const content = [
            mElem('header', i18n.sendToMessenger),
            mElem('scroll-hider', elem('messenger-contacts')),
            mElem('header', i18n.shareButton),
            mElem('scroll-hider', mContactsElem('popup-tile-wrapper', services))
        ];

        if (copy) {
            content.push(mElem('mobile-popup-copy-link', url));
        }

        return {
            content
        };
    };

    // TODO на будущее: вынести как паттерн мемоизации вызова шаблона
    const prevParams = {};

    templates['popup-body'] = params => {
        const mobilePopup = params.needExtendMessenger ? popupWithContacts : defaultPopup;
        return mobilePopup(Object.assign(prevParams, params));
    };

    return templates;
}

import extend from 'extend';
import bemNaming from 'bem-naming';

import { getOgTag, getMetaTag } from '../helpers';

/** @type {TemplatesConstructor} */
export function mobileTemplates(constructors, state) {
    const { elem, mElem, mBody } = constructors;
    const { i18n } = state;

    return {
        'popup-overlay': () => ({}),

        'popup-content': ({ services, url, customContent, copy, needExtendMessenger }) => {
            return {
                content: [
                    mElem('popup-header', { customContent }),
                    mBody('popup-body', { services, url, copy, needExtendMessenger })
                ]
            };
        },

        header: text => ({
            tag: 'h2',
            content: text
        }),

        'popup-header': ({ customContent: { description, image }}) => {
            if (!description) {
                const descriptionTag = getOgTag('description') || getOgTag('title') || getMetaTag('name', 'description');

                description = descriptionTag ? descriptionTag.getAttribute('content') : window.location.href;
            }

            if (!image) {
                const imageTag = getOgTag('image:secure_url') || getOgTag('image');

                image = imageTag && imageTag.getAttribute('content');
            }

            return {
                content: [
                    image ? mElem('og-image', image) : extend(elem('icon'), { mods: { 'og-image-stub': true }}),
                    mElem('popup-description', description),
                    extend(elem('icon'), { mods: { 'closing-cross': true }})
                ]
            };
        },

        'og-image': src => ({
            cls: bemNaming.stringify(elem('icon')),
            attrs: {
                style: `background-image: url('${src}')`
            }
        }),

        'popup-description': description => ({
            content: description
        }),

        'popup-tile': services => {
            const content = services.map(service => extend(elem('item', service), {
                content: extend(elem('link', service), {
                    content: [
                        mElem('mobile-popup-badge'),
                        mElem('mobile-popup-service-title', service.title)
                    ]
                })
            }));

            if (navigator.share) {
                content.push(mElem('native-share'));
            }

            return {
                tag: 'ul',
                content
            };
        },

        'native-share': () => extend(elem('item', { name: 'other' }), {
            content: mElem('native-share-fake-link')
        }),

        'native-share-fake-link': () => ({
            content: [
                extend(mElem('mobile-popup-badge'), { mods: { 'native-share': true }}),
                mElem('mobile-popup-service-title', i18n.otherServices)
            ]
        }),

        'mobile-popup-badge': iconUrl => ({
            tag: 'span',
            content: [elem('icon', iconUrl)]
        }),

        'mobile-popup-service-title': title => ({
            content: title
        }),

        'mobile-popup-copy-link': url => ({
            content: extend(elem('item', url), {
                tag: 'button',
                mods: {
                    copy: true,
                    'copy-link-button': true
                },
                content: [
                    extend(elem('icon'), { mods: { 'copy-icon': true }}),
                    mElem('link-title'),
                    elem('input_copy', url)
                ]
            })
        }),

        'link-title': () => ({
            tag: 'span',
            content: i18n.copyLink
        }),

        'copied-tooltip': () => ({
            content: i18n.linkCopied
        }),

        'scroll-hider': content => ({
            content
        })
    };
}

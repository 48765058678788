import bemNaming from 'bem-naming';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import { initTemplates, ConstructorName } from '../templates';
import { buildUrl, serializeParams } from './router';
import * as popup from './popup';
import bem from './bem';
import dom from './dom';
import createOptions from './options';
import { prepareServicesList } from './prepare-services';
import { getMessengerContacts } from './get-messenger-contacts';
import detectMobile from './detect-mobile';

const yDirectionClasses = [
    'ya-share2__popup_direction_top',
    'ya-share2__popup_direction_bottom'
];
const overlayClass = 'ya-share2__popup-overlay';

const xDirectionClasses = [
    'ya-share2__popup_x-direction_left',
    'ya-share2__popup_x-direction_right'
];

const ICON_SIZE_M = 24;
const ICON_SIZE_S = 18;
const POPUP_POSITION_OFFSET_M = 4;
const POPUP_POSITION_OFFSET_S = 3;

/**
 * Получить список дополнительных полей для плагинов
 *
 * @param  {Object} plugins
 * @return {Object}
 */
function getContentOptions(plugins) {
    return Object.keys(plugins).reduce((acc, name) => {
        const config = plugins[name];

        if (config.contentOptions) {
            acc[name] = config.contentOptions;
        }

        return acc;
    }, {});
}

/**
 * Дописывает get-параметры между путем и хэшом
 * @param {string} url
 * @param {string} query
 * @returns {string}
 */
const addQueryToUrl = (url, query) => {
    const [hostpath, hash] = url.split('#');
    return `${hostpath}?${query}#${hash}`;
};

let isMetrikaLoaded = false;
let metrikaCallbacks = [];
let needEmulateClick = false;

function resolveMetrika() {
    isMetrikaLoaded = true;
    metrikaCallbacks.forEach(fn => fn());
    metrikaCallbacks = [];
}

function onMetrikaLoaded(callback) {
    if (isMetrikaLoaded) {
        callback();
    } else {
        metrikaCallbacks.push(callback);
    }
}

export default class {
    constructor(domNode, params) {
        const { plugins, defaults, options, metrika } = params;
        const contentOptions = getContentOptions(plugins);

        const namespace = 'ya-share2.' + Math.random();

        this._params = params;
        this._domNode = domNode;
        this._namespace = namespace;
        this._plugins = plugins;
        this._options = createOptions(contentOptions, defaults, domNode.dataset, options);

        const lang = this._options.get('theme.lang');
        this._i18n = this._options.get(`i18n.${lang}`);

        this._setAsPopupFlag();
        this._setMobileFlag();
        this._setCopyFlag();

        this._initLayout(plugins, namespace);
        this._bindEvents(metrika);

        domNode.classList.add('ya-share2');
        domNode.classList.add('ya-share2_inited');

        this._options.get('hooks.onready').call(this);

        // Destroy method could be called inside the hook
        // so if you want to add any logic below
        // ensure that instance is not destroyed
    }

    _setAsPopupFlag() {
        this.asPopup = this._options.get('asPopup');
    }

    _setMobileFlag() {
        const hasForceCurtain = this._options.get('theme.forceCurtain');
        const hasNewMobileDesign = this._options.get('theme.curtain');

        this.hasCurtain = Boolean(hasForceCurtain || (hasNewMobileDesign && detectMobile()));
    }

    _setCopyFlag() {
        this.canCopy = Boolean(navigator.clipboard);
    }

    _isDestroyed() {
        return this._domNode === null;
    }

    _updateDomLinks() {
        this._morePopup = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'popup' })[0];

        this._closingCrosses = [].slice.call(bem.findInside(
            this._domNode,
            { block: 'ya-share2', elem: 'icon', modName: 'closing-cross' }
        ));

        this._copiedTooltip = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'copied-tooltip' })[0];

        if (this._morePopup && this._options.get('theme.popupPosition') === 'outer' && !this.asPopup) {
            this._createOutsidePopup();
        }
    }

    _destroyOutsidePopup() {
        document.body.removeChild(this._morePopupContainer);
    }

    _createOutsidePopup() {
        if (this._morePopupContainer) {
            this._destroyOutsidePopup();
        }

        // Оборачиваем выносимый попап в контейнер, чтобы сохранить визуальное представление
        var container = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'container' })[0];

        this._morePopupContainer = document.createElement('div');
        this._morePopupContainer.style.position = 'absolute';

        this._morePopupContainer.style['pointer-events'] = 'none';
        this._morePopup.style['pointer-events'] = 'all';

        this._morePopupContainer.className = container.className;

        const limit = this._options.get('theme.limit');
        const moreButtonType = this._options.get('theme.moreButtonType');
        if (parseInt(limit, 10) === 0 && Boolean(moreButtonType)) {
            this._morePopupContainer.classList.add(
                bemNaming.stringify({ block: 'ya-share2', elem: 'container', modName: 'alone' })
            );
        }

        this._morePopupContainer.appendChild(this._morePopup);

        document.body.appendChild(this._morePopupContainer);
    }

    updateContent(content) {
        if (this._isDestroyed()) {
            throw new Error('Could not operate on destroyed block.');
        }

        this._options.merge({ content });
        this._initLayout(this._params.plugins, this._namespace);
    }

    updateContentByService(contentByService) {
        if (this._isDestroyed()) {
            throw new Error('Could not operate on destroyed block.');
        }

        this._options.merge({ contentByService });
        this._initLayout(this._params.plugins, this._namespace);
    }

    destroy() {
        this._domNode.classList.remove('ya-share2_inited');
        this._domNode.innerHTML = '';
        this._domNode = null;

        if (this._morePopupContainer) {
            dom.remove(this._morePopupContainer);
            this._morePopupContainer = null;
        }

        document.body.removeEventListener('click', this._onClick);
        document.body.removeEventListener('touchstart', this._onTouchStart);
        document.body.removeEventListener('touchmove', this._onTouchMove);
        document.body.removeEventListener('touchend', this._onTouchEnd, { passive: false });
        document.body.removeEventListener('keydown', this._onKeydown);
    }

    _getContentForService(name) {
        const getOption = key => this._options.get(key, name);

        const data = {
            title: getOption('content.title'),
            description: getOption('content.description'),
            image: getOption('content.image'),
            url: getOption('content.url')
        };

        const contentOptions = this._plugins[name].contentOptions || {};
        Object.keys(contentOptions).forEach(option => {
            data[option] = getOption(`content.${option}`);
        });

        return data;
    }

    _setMessengerContacts(messengerContacts) {
        if (messengerContacts.length === 0) {
            const popupBody = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'popup-body' })[0];
            if (popupBody) {
                this._templates.replaceNode(popupBody, 'popup-body', { needExtendMessenger: false }, ConstructorName.mBody);
            }

            return;
        }

        if (!this.hasCurtain) {
            messengerContacts = messengerContacts.slice(0, 5);
        }

        const query = serializeParams({
            text: [
                this._options.get('content.title'),
                this._options.get('content.url')
            ].join('\n')
        });
        messengerContacts = messengerContacts.map(
            ({ iconUrl, locationUrl, title }) => ({
                title,
                name: `messenger${title.split(/\s/).join('')}`,
                iconUrl,
                location: addQueryToUrl(locationUrl, query)
            })
        );

        const messengerContactsElem = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'messenger-contacts' })[0];

        if (messengerContactsElem) {
            this._templates.update(
                messengerContactsElem,
                'messenger-contacts-list',
                messengerContacts,
                ConstructorName.messengerContactsList
            );
        }

        const icons = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'icon', modName: 'messenger-contact' });
        for (const icon of icons) {
            const iconUrl = icon.getAttribute('data-icon-url');
            icon.style = `background-image: url("${iconUrl}");`;
        }
    }

    _getCustomContent() {
        return {
            title: this._options.get('content.title'),
            description: this._options.get('content.description'),
            image: this._options.get('content.image')
        };
    }

    _initLayout(plugins, namespace) {
        const servicesList = this._options.get('theme.services').split(',');
        const limit = this._options.get('theme.limit');

        const needExtendMessenger = Boolean(
            this._options.get('theme.messengerContacts') &&
            !this.isBare() &&
            servicesList.length > limit &&
            window.fetch
        );

        if (needExtendMessenger) {
            getMessengerContacts(
                this._setMessengerContacts.bind(this),
                () => bem.findInside(this._domNode, { block: 'ya-share2', elem: 'messenger-frame' })[0]
            );
        }

        const services = prepareServicesList(
            servicesList,
            { limit, needExtendMessenger }
        );

        this._services = services
            .filter(name => plugins[name])
            .map(name => {
                const getOption = key => this._options.get(key, name);

                const data = this._getContentForService(name);

                const shareUrlConfig = plugins[name].config.shareUrl;
                const template = getOption('content.template', name);
                const templateConfig = shareUrlConfig[template] || shareUrlConfig.default;

                let shareUrl = buildUrl(templateConfig, data);

                return {
                    name: name,
                    title: plugins[name].i18n[getOption('theme.lang')],
                    location: shareUrl,
                    linkAttrs: plugins[name].linkAttrs,
                    popupDimensions: plugins[name].popupDimensions
                };
            });

        this._templates = initTemplates(this._i18n, this.hasCurtain);

        this._templates.update(this._domNode, 'container', {
            url: this._options.get('content.url'),
            customContent: this._getCustomContent(),
            theme: this._options.get('theme'),
            services: this._services,
            namespace: namespace,
            flags: {
                needExtendMessenger,
                asPopup: this.asPopup,
                canCopy: this.canCopy
            }
        });

        this._updateDomLinks();
    }

    getNonce() {
        return this._options.get('theme.nonce');
    }

    _onClick(e) {
        // Только если не сработал обработчик на 'touchstart'
        if (!needEmulateClick) {
            this._onBodyClick(e);
        }

        // Чистим флаг эмуляции клика, чтобы не заблокировать использование мыши,
        // 'touchstart' сработает раньше, чем 'click' и снова выставит флаг
        needEmulateClick = false;
    }

    _onTouchStart() { needEmulateClick = true; }
    _onTouchMove() { needEmulateClick = false; }
    _onTouchEnd(e) {
        if (needEmulateClick) {
            this._onBodyClick(e);
        }
    }

    _bindEvents(metrika) {
        this._onBodyClick = this._onBodyClick.bind(this, metrika);

        this._onClick = this._onClick.bind(this);
        document.body.addEventListener('click', this._onClick);

        // Танцы с бубном, потому что не весь Safari на iOS поддерживает событие 'click'
        // Для всех тачей клик эмулируем с помощью схемы "touchstart && !touchmove => клик"
        document.body.addEventListener('touchstart', this._onTouchStart);
        document.body.addEventListener('touchmove', this._onTouchMove);
        this._onTouchEnd = this._onTouchEnd.bind(this);
        document.body.addEventListener('touchend', this._onTouchEnd, { passive: false });

        this._onKeydown = this._onKeydown.bind(this);
        document.body.addEventListener('keydown', this._onKeydown);

        document.addEventListener('yacounter' + metrika._id + 'inited', this._onMetrikaInited);

        onMetrikaLoaded(() => {
            metrika.ym('params', {
                services: this._services.map(service => service.name).join(',')
            });
        });
    }

    _onKeydown(event) {
        const keyCode = event.which || event.keyCode;

        switch (keyCode) {
            case 27: // ESC
                this._closePopup(event);
                break;

            default:
                break;
        }
    }

    _onBodyClick(metrika, event) {
        const target = dom.getTarget(event);

        // Если нажали на крестик или оверлей
        if (this._closingCrosses.indexOf(target) !== -1 || target.classList.contains(overlayClass)) {
            this._closePopup(event);

            return;
        }

        // Если нажали на шапку с тизером, но не на крестик
        const isTargetInPopupHeader = Boolean(bem.findOutside(target, { block: 'ya-share2', elem: 'popup-header' }));
        if (isTargetInPopupHeader) {
            return;
        }

        const targetContainer = bem.findOutside(target, { block: 'ya-share2', elem: 'container' });
        const container = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'container' })[0];
        if (!targetContainer || (targetContainer !== container && targetContainer !== this._morePopupContainer)) {
            this._closePopup(event);
            return;
        }

        const item = bem.findOutside(target, { block: 'ya-share2', elem: 'item' });
        if (!item) {
            return;
        }

        const isTargetInPopupContent = Boolean(bem.findOutside(target, { block: 'ya-share2', elem: 'popup-content' }));
        if (bem.getMod(item, 'more') && !isTargetInPopupContent) {
            this._onMoreClick(event);

            return;
        }

        if (bem.getMod(item, 'copy')) {
            this._onCopyClick(event);

            return;
        }

        if (bem.getMod(item, 'more')) {
            return;
        }

        if (bem.getMod(item, 'service') === 'other') {
            this._onNavigatorShareClick(event);

            return;
        }

        this._onServiceClick(event, item, metrika);
    }

    _triggerCopiedTooltip() {
        this._copiedTooltip.classList.add('ya-share2__copied-tooltip_shown');

        const _copiedTooltip = this._copiedTooltip;
        setTimeout(() => {
            _copiedTooltip.classList.remove('ya-share2__copied-tooltip_shown');
        }, 2000);
    }

    _onCopyClick(event) {
        if (!this.canCopy) {
            this._closePopup(event);
            return;
        }

        const url = this._options.get('content.url');

        navigator.clipboard.writeText(url)
            .then(() => {
                if (this.hasCurtain) {
                    this._triggerCopiedTooltip();
                }

                this._closePopup(event);
            })
            .catch(() => { this._closePopup(event); });
    }

    _onNavigatorShareClick(event) {
        const shareData = {
            url: this._options.get('content.url'),
            text: this._options.get('content.description'),
            title: this._options.get('content.title')
        };

        event.stopPropagation();
        event.preventDefault();

        navigator.share(shareData)
            .then(() => { this._closePopup(); })
            .catch(() => { this._closePopup(); });
    }

    _findPopupClass(classList) {
        return classList.filter(className => this._morePopup.classList.contains(className))[0];
    }

    _calcPopupHorizotalDirection(clientRect) {
        let xDirectionClass = this._findPopupClass(xDirectionClasses);
        if (xDirectionClass) {
            this._morePopup.classList.toggle(xDirectionClass);
            return;
        }

        const listDirection = this._options.get('theme.direction');
        xDirectionClass = listDirection === 'horizontal' ? xDirectionClasses[0] : xDirectionClasses[1];

        const { left, width } = clientRect;
        const xRight = left + width;

        const moreButtonWidth = bem
            .findInside(this._domNode, { block: 'ya-share2', elem: 'item', modName: 'more' })[0]
            .getBoundingClientRect()
            .width;

        if (xDirectionClass === xDirectionClasses[0] && left < 0) {
            const predictedX = xRight - moreButtonWidth + width;

            if (predictedX < window.innerWidth) {
                xDirectionClass = xDirectionClasses[1];
            }
        } else if (xDirectionClass === xDirectionClasses[1] && xRight > window.innerWidth) {
            const predictedX = left + moreButtonWidth - width;

            if (predictedX > 0) {
                xDirectionClass = xDirectionClasses[0];
            }
        }

        this._morePopup.classList.toggle(xDirectionClass);
    }

    _calcPopupVerticalDirection(clientRect) {
        const yDirectionClass = this._findPopupClass(yDirectionClasses);
        if (yDirectionClass) {
            this._morePopup.classList.toggle(yDirectionClass);
            return;
        }

        const { top, height } = clientRect;

        const size = this._options.get('theme.size');
        const verticalOffset = size === 'm' ?
            ICON_SIZE_M + POPUP_POSITION_OFFSET_M :
            ICON_SIZE_S + POPUP_POSITION_OFFSET_S;

        const contentHeight = height + verticalOffset;
        if (top - contentHeight > 0 && top + contentHeight > window.innerHeight) {
            this._morePopup.classList.toggle(yDirectionClasses[0]);
        } else {
            this._morePopup.classList.toggle(yDirectionClasses[1]);
        }
    }

    _handleAutoDirection() {
        const clientRect = this._morePopup.getBoundingClientRect();

        this._calcPopupHorizotalDirection(clientRect);

        if (this._options.get('theme.popupDirection') === 'auto') {
            this._calcPopupVerticalDirection(clientRect);
        }
    }

    _onMoreClick(event) {
        if (this._morePopupContainer) {
            let anchor = bem.findInside(this._domNode, {
                block: 'ya-share2',
                elem: 'item',
                modName: 'more'
            })[0];
            let { top, left, width, height } = dom.getRectRelativeToDocument(anchor);

            this._morePopupContainer.style.top = `${top}px`;
            this._morePopupContainer.style.left = `${left}px`;
            this._morePopupContainer.style.width = `${width}px`;
            this._morePopupContainer.style.height = `${height}px`;
        }

        this._openPopup();

        event.preventDefault();
        event.stopPropagation();
    }

    _onServiceClick(event, item, metrika) {
        // Почему-то клики ссылки с якорем (например, Яндекс.Мессенджер) не работают при закрытом попапе
        const _morePopup = this._morePopup;
        setTimeout(() => {
            this._closePopup(event, _morePopup);
        }, 50);

        const serviceName = bem.getMod(item, 'service');

        if (!serviceName) {
            return;
        }

        const service = this._services.filter(service => service.name === serviceName)[0];

        if (!service) {
            return;
        }

        this._options.get('hooks.onshare').call(this, service.name);

        // If `destroy` method was called from the hook
        if (this._isDestroyed()) {
            return;
        }

        const useLinks = this._options.get('theme.useLinks');

        if (!useLinks && service.popupDimensions) {
            const link = bem.findInside(item, {
                block: 'ya-share2',
                elem: 'link'
            })[0];

            event.preventDefault();
            event.stopPropagation();

            popup.open('ya-share2', link.href, service.popupDimensions);
        }

        var items = bem.findInside(this._domNode, { block: 'ya-share2', elem: 'item' });
        var buttonIndex = [].indexOf.call(items, item);

        onMetrikaLoaded(() => {
            metrika.ym('reachGoal', 'BUTTON_CLICK', { serviceName, buttonIndex });
        });
    }

    _onMetrikaInited() {
        resolveMetrika();
    }

    isBare() {
        return Boolean(this._options.get('theme.bare'));
    }

    _openPopup() {
        this._morePopup.classList.toggle('ya-share2__popup_visible');

        this._handleAutoDirection();

        if (this.hasCurtain) {
            if (!this.popupBody) {
                this.popupBody = bem.findInside(this._morePopup, { block: 'ya-share2', elem: 'popup-body' })[0];
            }

            disableBodyScroll(this.popupBody);
        }
    }

    _closePopup(event, popup) {
        popup = popup || this._morePopup;

        if (popup && popup.classList.contains('ya-share2__popup_visible')) {
            if (event instanceof Event) {
                event.stopPropagation();
                event.preventDefault();
            }

            if (this.popupBody) {
                enableBodyScroll(this.popupBody);
            }

            popup.classList.remove('ya-share2__popup_visible');

            for (const className of xDirectionClasses) {
                popup.classList.remove(className);
            }

            if (this._options.get('theme.popupDirection') === 'auto') {
                for (const className of yDirectionClasses) {
                    popup.classList.remove(className);
                }
            }
        }

        this._options.get('hooks.onClosePopup').call(this);
    }
}

/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://getpocket.com/save',
                params: {
                    url: 'url',
                    title: 'title'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Pocket',
        be: 'Pocket',
        en: 'Pocket',
        hy: 'Pocket',
        ka: 'Pocket',
        kk: 'Pocket',
        ro: 'Pocket',
        ru: 'Pocket',
        tr: 'Pocket',
        tt: 'Pocket',
        uk: 'Pocket',
        uz: 'Pocket'
    },

    color: '#ee4056'
};

import extend from 'extend';

/** @type {TemplatesConstructor} */
export function getMessengerContactsListTemplate(constructors, state) {
    const { elem, mElem } = constructors;
    const { hasCurtain } = state;

    const templates = {};

    if (hasCurtain) {
        templates['messenger-contacts-list'] = contacts => ({
            mods: { mobile: true },
            tag: 'ul',
            content: contacts.map(contact => extend(elem('item', contact), {
                content: extend(elem('link', contact), {
                    content: [
                        mElem('mobile-popup-badge', contact.iconUrl),
                        mElem('mobile-popup-service-title', contact.title)
                    ]
                })
            }))
        });
    } else {
        templates['messenger-contacts-list'] = contacts => ({
            mods: { desktop: true },
            tag: 'ul',
            content: contacts.map(contact => elem('item', contact))
        });
    }

    return templates;
}

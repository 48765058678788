/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'http://share.v.t.qq.com/index.php?c=share&a=index',
                params: {
                    url: 'url',
                    title: 'title',
                    pic: 'image'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Tencent Weibo',
        be: 'Tencent Weibo',
        en: 'Tencent Weibo',
        hy: 'Tencent Weibo',
        ka: 'Tencent Weibo',
        kk: 'Tencent Weibo',
        ro: 'Tencent Weibo',
        ru: 'Tencent Weibo',
        tr: 'Tencent Weibo',
        tt: 'Tencent Weibo',
        uk: 'Tencent Weibo',
        uz: 'Tencent Weibo'
    },

    color: '#53a9d7'
};

/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'http://service.weibo.com/share/share.php?type=3',
                params: {
                    url: 'url',
                    pic: 'image',
                    title: 'title'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Sina Weibo',
        be: 'Sina Weibo',
        en: 'Sina Weibo',
        hy: 'Sina Weibo',
        ka: 'Sina Weibo',
        kk: 'Sina Weibo',
        ro: 'Sina Weibo',
        ru: 'Sina Weibo',
        tr: 'Sina Weibo',
        tt: 'Sina Weibo',
        uk: 'Sina Weibo',
        uz: 'Sina Weibo'
    },

    color: '#c53220'
};

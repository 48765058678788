/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://web.skype.com/share',
                params: {
                    url: 'url'
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'Skype',
        be: 'Skype',
        en: 'Skype',
        hy: 'Skype',
        ka: 'Skype',
        kk: 'Skype',
        ro: 'Skype',
        ru: 'Skype',
        tr: 'Skype',
        tt: 'Skype',
        uk: 'Skype',
        uz: 'Skype'
    },

    color: '#00aff0'
};

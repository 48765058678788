/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://www.livejournal.com/update.bml',
                params: {
                    subject: 'title',
                    event: {
                        options: ['url', 'description'],
                        separator: '\n'
                    }
                }
            }
        }
    },

    popupDimensions: [800, 520],

    i18n: {
        az: 'LiveJournal',
        be: 'LiveJournal',
        en: 'LiveJournal',
        hy: 'LiveJournal',
        ka: 'LiveJournal',
        kk: 'LiveJournal',
        ro: 'LiveJournal',
        ru: 'LiveJournal',
        tr: 'LiveJournal',
        tt: 'LiveJournal',
        uk: 'LiveJournal',
        uz: 'LiveJournal'
    },

    color: '#0d425a'
};

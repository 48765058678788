/**
 * Запрашивает iframe с информацией о контактах и возвращает ее
 * @param {function} cb Коллбэк после получения данных
 * @param {function} getIframe Коллбэк для получения ссылки на фрейм
 * @returns {undefined}
 */
export function getMessengerContacts(cb, getIframe) {
    window.addEventListener('message', ({ data, origin }) => {
        if (
            window.location.origin === origin &&
            data.type === 'share_iframe' &&
            data.payload &&
            Array.isArray(data.payload.items)
        ) {
            cb(data.payload.items);
        }
    });

    // Браузер не подставляет в iframe заголовок Origin, который нужен мессенджеру
    fetch('https://api.messenger.yandex.ru/share/index.html', { credentials: 'include' })
        .then(response => {
            if (!response.ok) {
                throw new Error('Couldn\'t get contacts from the Messenger');
            }

            response.text().then(response => {
                const iframeDoc = getIframe().contentDocument;
                iframeDoc.open().write(response);
                iframeDoc.close();
            });
        })
        .catch(err => {
            console.error(err);
            cb([]);
        });
}

/* jscs:disable maximumLineLength */
export default {
    config: {
        shareUrl: {
            default: {
                baseUrl: 'https://surfingbird.ru/share',
                params: {
                    url: 'url',
                    title: 'title',
                    desc: 'description'
                }
            }
        }
    },

    popupDimensions: [500, 170],

    i18n: {
        az: 'Surfingbird',
        be: 'Surfingbird',
        en: 'Surfingbird',
        hy: 'Surfingbird',
        ka: 'Surfingbird',
        kk: 'Surfingbird',
        ro: 'Surfingbird',
        ru: 'Surfingbird',
        tr: 'Surfingbird',
        tt: 'Surfingbird',
        uk: 'Surfingbird',
        uz: 'Surfingbird'
    },

    color: '#30baff'
};
